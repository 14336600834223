import { nanoid } from 'nanoid';
const secureRandomPassword = require('secure-random-password');

console.log(secureRandomPassword.randomPassword());
// GET ALL THE VARIABLES WE ARE GONNA NEED
const passwordServer = document.getElementById("passwordServer");
const passwordWeb = document.getElementById("passwordWeb");
const passwordUnpriv = document.getElementById("passwordUnpriv");
const copyTyp1 = document.getElementById("copyTyp1");
const copyTyp2 = document.getElementById("copyTyp2");
const copyTyp3 = document.getElementById("copyTyp3");
const generate = document.getElementById("generate");

// Listen for the click event on the `copy` and copy the password to your clipboard
copyTyp1.addEventListener("click", () => {
	var TempText = document.createElement("input");
	TempText.value = passwordTyp1.innerText;
	document.body.appendChild(TempText);
	TempText.select();
	document.execCommand("copy");
	document.body.removeChild(TempText);
});

copyTyp2.addEventListener("click", () => {
	var TempText = document.createElement("input");
	TempText.value = passwordTyp2.innerText;
	document.body.appendChild(TempText);
	TempText.select();
	document.execCommand("copy");
	document.body.removeChild(TempText);
});

copyTyp3.addEventListener("click", () => {
	var TempText = document.createElement("input");
	TempText.value = passwordTyp3.innerText;
	document.body.appendChild(TempText);
	TempText.select();
	document.execCommand("copy");
	document.body.removeChild(TempText);
});

copyNanoid1.addEventListener("click", () => {
        var TempText = document.createElement("input");
        TempText.value = passwordNanoid1.innerText;
        document.body.appendChild(TempText);
        TempText.select();
        document.execCommand("copy");
        document.body.removeChild(TempText);
});


// Listen for click event on `generate` button
generate.addEventListener("click", () => {
	genPassword();
});

function genPassword() {
	passwordTyp1.innerText = secureRandomPassword.randomPassword({ characters: [{ characters: secureRandomPassword.upper}, { characters: secureRandomPassword.symbols, exactly: 1 }, { characters: secureRandomPassword.digits, exactly: 1}, secureRandomPassword.lower ], length: 32 });
	passwordTyp2.innerText = secureRandomPassword.randomPassword({ characters: [{ characters: secureRandomPassword.upper}, { characters: secureRandomPassword.symbols}, { characters: secureRandomPassword.digits}, secureRandomPassword.lower ], length: 32 });
	passwordTyp3.innerText = secureRandomPassword.randomPassword({ characters: [{ characters: secureRandomPassword.upper}, { characters: secureRandomPassword.symbols, exactly: 1 }, { characters: secureRandomPassword.digits, exactly: 1}, secureRandomPassword.lower ], length: 8 });

	passwordNanoid1.innerText =  nanoid();

}

window.onload = function () {
	genPassword();
}

